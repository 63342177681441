import styled from 'styled-components'

const SchedulingLiveStyled = styled.div`
  .open-detail {
    padding-bottom: 16px;
    .btn-back {
      cursor: pointer;
      font-size: 16px;
      color: ${({ theme }) => theme.color.white};
      .img-text-btn {
        text-decoration: none;
        color: ${({ theme }) => theme.color.white};
      }
    }

    .line-grey {
      padding-top: 8px;
      border-bottom: 1px solid #2d3936;
      z-index: 1;
    }

    .container {
      .name-container {
        padding-top: 8px;
        margin-bottom: 10px;

        .name,
        .time-race {
          font-size: 16px;
          line-height: 20px;
        }
        .time-race {
          margin: 0px;
        }
      }

      .race-info-container {
        ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
          gap: 16px;
        }

        .info-left {
          ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
            padding-bottom: 10px;
          }

          ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
            overflow-x: scroll;
          }

          .left {
            gap: 20px;

            ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
              width: max-content;
            }

            .info-left-item {
              gap: 12px;

              .title {
                font-size: 14px;
                line-height: 14px;
                color: #ccc;
              }

              .value {
                font-size: 16px;
                line-height: 19px;
              }
            }
          }

          .time-race {
            font-size: 20px;
            line-height: 20px;
            margin: 0px;
          }
        }

        .title {
          color: #ccc;
          font-size: 14px;
        }
        .info-right {
          gap: 12px;
          .info-right-item {
            .title {
              font-size: 14px;
              line-height: 14px;
              margin-bottom: 12px;
              color: #ccc;
            }

            .value {
              font-size: 16px;
              line-height: 19px;

              img {
                width: 15px;
                height: 15px;
                margin-top: -3px;
                margin-left: 3px;
              }

              .game-token-merah {
                width: 20px;
                height: 20px;
                margin-top: -3px;
              }

              .icon__old {
                opacity: 0.5;
              }
            }
          }
        }

        .replay-btn-container {
          clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 14px, 100% 100%, 14px 100%, 0% calc(100% - 14px));
          background: ${({ theme }) => theme.color.primary};

          padding: 1px;
          width: 112px;
          aspect-ratio: 146 / 42;
          margin: 6px 0px;

          .replay-btn {
            clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 14px, 100% 100%, 14px 100%, 0% calc(100% - 14px));
            border: none;
            background-color: ${({ theme }) => theme.color.black};

            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }

    .table-container {
      margin-top: 40px;
    }

    .prize-border {
      left: 0;
      top: -6px;
      width: 65px;
      height: 8px;
    }
    .career-horse {
      color: #fff566 !important;
    }

    .btn-view-result {
      margin-left: 47px;
      cursor: pointer;
    }

    .horse-rank > .suffix {
      display: none;
    }

    .horse-rank {
      .horse__name__box {
        width: 200px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .horse__name {
          max-width: 145px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: left;
        }

        .region {
          margin-left: 5px;
          width: 25px;
          height: 25px;
          display: flex;

          img {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }

    .weather-box {
      width: 55px;
      height: 55px;
      margin-left: 20px;
      position: relative;
      img {
        position: absolute;
        top: -4px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: scale(1.2);
      }

      img[src=""], img:not([src]){
        opacity: 0;
      }

      img[src="*"]{
        opacity: 1;
      }
    }

    .race-type {
      padding: 0 0 6px 20px;
    }
  }

  .jackpot-box {
      width: 55px;
      height: 55px;
      position: relative;
      margin-left: 20px;
      img {
        position: absolute;
        top: -6px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: scale(1.2);
      }

      img[src=""], img:not([src]){
        opacity: 0;
      }

      img[src="*"]{
        opacity: 1;
      }
    }

    .race-type {
      padding: 0 0 6px 20px;
    }
  }

  .box_tokyo {
    font-weight: 600;
    background-color: #ff1f6a;
    width: 80px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
  }

  .box_seoul {
    font-weight: 600;
    background-color: #1ecfcf;
    width: 80px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
  }

  .box_galaxy_road {
    font-weight: 600;
    background-color: #e6a01f;
    width: 120px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
  }
`

export default SchedulingLiveStyled
