/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SPECIAL_MESSAGE } from 'i18n/constants'
import { RaceStatus } from 'models'
import { ORANGE_LINE_LEAN_LEFT, ORANGE_LINE_LEAN_RIGHT, POLYGON } from 'assets/images'
import BoardViewTimeStyled from './styled'
import { NUMBER } from 'apps/constants'
import { calculateDuration } from 'utils/helper'

interface BoardViewTimeProps {
  raceDetail?: any
  status?: string
  sendStatusToParent: (status: boolean) => void
}

function BoardViewTimeOpen({
  raceDetail = '',
  status,
  sendStatusToParent = () => {}
}: BoardViewTimeProps) {
  const { t } = useTranslation()
  const timerRef = useRef<any>('')
  const [durationD, setDurationD] = useState<any>('0')
  const [durationH, setDurationH] = useState<any>('0')
  const [durationM, setDurationM] = useState<any>('0')
  const [durationS, setDurationS] = useState<any>('0')
  const [disableTime, setDisableTime] = useState<any>(true)
  const { OPEN } = RaceStatus
  
  useEffect(() => {
    if (raceDetail?.finished_at) {
      timerRef.current = setInterval(() => {
        getDuration(raceDetail?.finished_at)
      }, NUMBER.THOUSAND)
    } else if (raceDetail?.finished_after) {
      timerRef.current = setInterval(() => {
        getDuration(raceDetail?.finished_after * 60000 + raceDetail?.public_at)
      }, NUMBER.THOUSAND)
    }

    return () => {
      clearInterval(timerRef.current)
    }
  }, [raceDetail])

  const getDuration = (data: any) => {
    const durationT: any = calculateDuration(data)
    setDurationD(Math.floor(durationT?.asDays()) || NUMBER.ZERO)
    setDurationH(durationT?.hours() || NUMBER.ZERO)
    setDurationM(durationT?.minutes() || NUMBER.ZERO)
    setDurationS(durationT?.seconds() || NUMBER.ZERO)
    if (Math.floor(durationT?.asDays()) === NUMBER.ZERO &&
      durationT?.hours() === NUMBER.ZERO &&
      durationT?.minutes() === NUMBER.ZERO &&
      durationT?.seconds() === NUMBER.ZERO) {
      sendStatusToParent(true)
      setDisableTime(false)
    }
  }

  const displayNumberTime = (num: number) => {
    return num > NUMBER.NINE ? num : `0${num}`
  }

  const handleDisplayBoardView = () => {
    let resultBoardView = null
    if (status && status === OPEN) {
      resultBoardView = disableTime ? <>
          <div className='display-time font-bold'>
            <span>{ displayNumberTime(durationD) }</span>
            <span>{ displayNumberTime(durationH) }</span>
            <span>{ displayNumberTime(durationM) }</span>
            <span>{ displayNumberTime(durationS) }</span>
          </div>
          <div className='hour-min-sec font-bold'>
            <span>{t(`${SPECIAL_MESSAGE}.day`)}</span>
            <span>{t(`${SPECIAL_MESSAGE}.hour`)}</span>
            <span>{t(`${SPECIAL_MESSAGE}.min`)}</span>
            <span>{t(`${SPECIAL_MESSAGE}.sec`)}</span>
          </div>
        </> :
        <div className='processing-race font-bold'>Processing...</div>
    }
    return resultBoardView
  }

  return (
    <BoardViewTimeStyled>
      <div className='board-view-time'>
        {handleDisplayBoardView()}
        <div className='orange-line-lean pb-3'>
          <div className='orange-line-lean-left'>
            <div>
              <img src={ORANGE_LINE_LEAN_LEFT} alt='' />
            </div>
          </div>
          <div className='polygon'>
            <img src={POLYGON} alt='' />
          </div>
          <div className='orange-line-lean-right'>
            <div>
              <img src={ORANGE_LINE_LEAN_RIGHT} alt='' />
            </div>
          </div>
        </div>
      </div>
    </BoardViewTimeStyled>
  )
}

export default BoardViewTimeOpen
